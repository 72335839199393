exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-apostol-js": () => import("./../../../src/pages/books/apostol.js" /* webpackChunkName: "component---src-pages-books-apostol-js" */),
  "component---src-pages-books-chasoslov-js": () => import("./../../../src/pages/books/chasoslov.js" /* webpackChunkName: "component---src-pages-books-chasoslov-js" */),
  "component---src-pages-books-evangelie-js": () => import("./../../../src/pages/books/evangelie.js" /* webpackChunkName: "component---src-pages-books-evangelie-js" */),
  "component---src-pages-books-index-js": () => import("./../../../src/pages/books/index.js" /* webpackChunkName: "component---src-pages-books-index-js" */),
  "component---src-pages-books-kanonnik-js": () => import("./../../../src/pages/books/kanonnik.js" /* webpackChunkName: "component---src-pages-books-kanonnik-js" */),
  "component---src-pages-books-pogrebenie-js": () => import("./../../../src/pages/books/pogrebenie.js" /* webpackChunkName: "component---src-pages-books-pogrebenie-js" */),
  "component---src-pages-books-pravilnye-kanony-js": () => import("./../../../src/pages/books/pravilnye-kanony.js" /* webpackChunkName: "component---src-pages-books-pravilnye-kanony-js" */),
  "component---src-pages-books-prichastnye-chasy-js": () => import("./../../../src/pages/books/prichastnye-chasy.js" /* webpackChunkName: "component---src-pages-books-prichastnye-chasy-js" */),
  "component---src-pages-books-psaltyr-js": () => import("./../../../src/pages/books/psaltyr.js" /* webpackChunkName: "component---src-pages-books-psaltyr-js" */),
  "component---src-pages-calendar-index-js": () => import("./../../../src/pages/calendar/index.js" /* webpackChunkName: "component---src-pages-calendar-index-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-library-js": () => import("./../../../src/pages/library.js" /* webpackChunkName: "component---src-pages-library-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-quiz-kruk-js": () => import("./../../../src/pages/quiz/kruk.js" /* webpackChunkName: "component---src-pages-quiz-kruk-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-znamennoe-blazheny-js": () => import("./../../../src/pages/znamennoe/blazheny.js" /* webpackChunkName: "component---src-pages-znamennoe-blazheny-js" */),
  "component---src-pages-znamennoe-heruvimskie-js": () => import("./../../../src/pages/znamennoe/heruvimskie.js" /* webpackChunkName: "component---src-pages-znamennoe-heruvimskie-js" */),
  "component---src-pages-znamennoe-index-js": () => import("./../../../src/pages/znamennoe/index.js" /* webpackChunkName: "component---src-pages-znamennoe-index-js" */),
  "component---src-pages-znamennoe-klirosnik-js": () => import("./../../../src/pages/znamennoe/klirosnik.js" /* webpackChunkName: "component---src-pages-znamennoe-klirosnik-js" */),
  "component---src-pages-znamennoe-liturgiya-irgizskogo-rospeva-js": () => import("./../../../src/pages/znamennoe/liturgiya-irgizskogo-rospeva.js" /* webpackChunkName: "component---src-pages-znamennoe-liturgiya-irgizskogo-rospeva-js" */),
  "component---src-pages-znamennoe-na-malom-vhode-js": () => import("./../../../src/pages/znamennoe/na-malom-vhode.js" /* webpackChunkName: "component---src-pages-znamennoe-na-malom-vhode-js" */),
  "component---src-pages-znamennoe-otche-nash-js": () => import("./../../../src/pages/znamennoe/otche-nash.js" /* webpackChunkName: "component---src-pages-znamennoe-otche-nash-js" */),
  "component---src-pages-znamennoe-rozhestvo-hristovo-js": () => import("./../../../src/pages/znamennoe/rozhestvo-hristovo.js" /* webpackChunkName: "component---src-pages-znamennoe-rozhestvo-hristovo-js" */),
  "component---src-pages-znamennoe-simvol-very-js": () => import("./../../../src/pages/znamennoe/simvol-very.js" /* webpackChunkName: "component---src-pages-znamennoe-simvol-very-js" */),
  "component---src-pages-znamennoe-stihovny-js": () => import("./../../../src/pages/znamennoe/stihovny.js" /* webpackChunkName: "component---src-pages-znamennoe-stihovny-js" */),
  "component---src-pages-znamennoe-velichaniya-js": () => import("./../../../src/pages/znamennoe/velichaniya.js" /* webpackChunkName: "component---src-pages-znamennoe-velichaniya-js" */),
  "component---src-pages-znamennoe-vsenoshnoe-bdenie-js": () => import("./../../../src/pages/znamennoe/vsenoshnoe-bdenie.js" /* webpackChunkName: "component---src-pages-znamennoe-vsenoshnoe-bdenie-js" */),
  "component---src-templates-kanon-js": () => import("./../../../src/templates/kanon.js" /* webpackChunkName: "component---src-templates-kanon-js" */)
}

